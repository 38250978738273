import AgreementItem from '@modules/Edo/Agreement';
import AgreementCS from '@modules/Edo/AgreementsCS/AgreementsCS';
import EdoAttributeForm from '@modules/Edo/EdoAttribute/EdoAttributeForm';
import EdoRowColumnForm from '@modules/Edo/EdoRowColumn/EdoRowColumnForm';
import EdoUploadForm from '@modules/Edo/EdoUploadForm';
import EdoRemark from '@modules/Edo/EdoRemark';
import { useOutletContext } from 'react-router-dom';
import { Form } from 'antd';
import useAuth from '@hooks/useAuth';

import { ContextType } from '.';
import { useEffect, useState } from 'react';
import EdoMatching from '@modules/Edo/EdoMathcing/EdoMatching';
import CreateOrderDoc from "@modules/Edo/CreateOrderDoc/CreateOrderDoc";

function EdoDocumentPage() {
  const { docEdo, settings, colleaguesList, handbookList, departmentList } =
    useOutletContext<ContextType>();
  const [form] = Form.useForm();
  const [matchingForm] = Form.useForm();
  const auth = useAuth();
  const [activeAgreementsCS, setActiveAgreementsCS] = useState<boolean>(false);
  const findTd = Object.keys(auth?.user?.data?.permission?.access_role).filter(
    (key) =>
      auth?.user?.data?.permission?.access_role[key].slug.toLowerCase() === 'td'
  );
  const isAdmin = findTd.length > 0 || auth?.user?.data?.permission?.is_admin;

  const isDisabledForm = docEdo.data?.stage_id
    ? docEdo.data?.stage_id != 1
    : docEdo.data?.stage?.slug !== 'in_work' || !docEdo.data.active;

  /**
   * Прописываем Title документа в виде ИмяДокумента ТипДокумента
   */
  useEffect(() => {
    const docType = docEdo?.data?.class?.full_name;
    const docName = docEdo?.data?.document_id;

    if (docType && docName) {
      document.title = `${docName} ${docType}`;
    }

    // TODO: Добавить условие чтобы при возврате назад он проставлял заголовок предыдущей страницы
  }, [docEdo]);

  console.log('docEdo?.data', docEdo?.data);

  return (
    <>
      {settings.show_table && settings.row_column_editor.length > 0 && (
        <EdoRowColumnForm
          id={docEdo.data.id}
          row_column_editor={settings.row_column_editor}
          button_editor={settings.button_editor}
          data={docEdo.data.doc_row}
          colleaguesList={colleaguesList}
          departmentList={departmentList}
          handbookList={handbookList}
          isDisabledForm={isAdmin ? !isAdmin : isDisabledForm}
          isDisabledStatus={isDisabledForm}
          isAdmin={isAdmin}
          attrForm={form}
          docData={docEdo.data}
          constant={settings?.constant}
          isDocEditCs={settings?.docAccess?.edit}
        />
      )}
      {docEdo?.data?.doc_order &&
          <CreateOrderDoc
              docCreateAccess={docEdo?.data?.doc_order && docEdo?.data?.docOrderCreateAccess}
              currentDocId={docEdo?.data?.id}
          />}
      {settings.remark.remark && (
        <EdoRemark
          id={docEdo.data.id}
          settings={settings.remark.remark}
          isDisabledForm={isAdmin ? !isAdmin : isDisabledForm}
          name="remark"
          data={{ remark: docEdo.data.remark }}
          isDisabledStatus={isDisabledForm}
          constant={settings?.constant}
          isDocEditCs={settings?.docAccess?.edit}
        />
      )}
      {settings.remark.remark2 && (
        <EdoRemark
          id={docEdo.data.id}
          settings={settings.remark.remark2}
          isDisabledForm={isAdmin ? !isAdmin : isDisabledForm}
          name="remark2"
          data={{ remark2: docEdo.data.remark2 }}
          isDisabledStatus={isDisabledForm}
        />
      )}
      {docEdo?.data?.class?.constant === 'RecReport' && (
        <EdoMatching form={matchingForm}/>
      )}
      {settings.attribute_editor.length > 0 && (
        <EdoAttributeForm
          id={docEdo.data.id}
          attribute_editor={settings.attribute_editor}
          data={docEdo.data.attributes}
          colleaguesList={colleaguesList}
          departmentList={departmentList}
          handbookList={handbookList}
          isDisabledForm={isAdmin ? !isAdmin : isDisabledForm}
          form={form}
          isDisabledStatus={isDisabledForm}
          constant={settings.constant}
          isDocEditCs={settings?.docAccess?.edit}
        />
      )}
      {settings.doc_file_editor &&
        Object.keys(settings.doc_file_editor).map((item, index) => {
          if (settings.doc_file_editor[item].show_column) {
            return (
              <EdoUploadForm
                settings={settings.doc_file_editor[item]}
                id={docEdo.data.id}
                files={docEdo.data.doc_file}
                isDisabledForm={isAdmin ? !isAdmin : isDisabledForm}
                key={index}
                form={form}
                isDisabledStatus={isDisabledForm}
              />
            );
          }
        })}

      <AgreementItem
        remarkTemplate={docEdo.data.remark_template}
        documentID={docEdo.data.id}
        docCompanyId={docEdo?.data?.class?.company_id}
        status={docEdo.data?.status?.constant}
        isDisabledForm={isAdmin ? !isAdmin : isDisabledForm}
        classType={docEdo?.data?.class?.constant}
        disabledButton={
          docEdo.data.active !== undefined ? !docEdo.data.active : false
        }
        docAccess={settings?.docAccess}
        isDisabledStatus={isDisabledForm}
        handleActiveCS={() => setActiveAgreementsCS(!activeAgreementsCS)}
      />

      {
         activeAgreementsCS && (
          <AgreementCS 
            class_id={docEdo?.data?.class?.id}
            docEdoId={docEdo?.data?.id}
            attributes={docEdo?.data?.attributes}
            disabledButton={
              docEdo.data.active !== undefined ? !docEdo.data.active : false
            }
          />
         )
      }
    </>
  );
}

export default EdoDocumentPage;
