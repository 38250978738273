import { useEffect } from 'react';
import {
  Col,
  DatePicker,
  Form,
  Modal,
  notification,
  Row,
  Input,
  Divider,
  Result,
  Button,
} from 'antd';
import { useImmerReducer } from 'use-immer';
import axios from 'axios';
import axiosServices from '@services/api/axios';

import useCounter from '../../hooks/useCounter';

import { useNavigate } from 'react-router-dom';
import { CoordinationType } from '@modules/Agreement/type';
import dayjs from 'dayjs';
import Approvers from '@modules/common/Approvers';
import api from './services/api';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

const { TextArea } = Input;

interface Settings {
  id: number;
  show_client: boolean;
  show_stage: boolean;
  form_columns: any[];
  show_period: boolean;
  disable_period: boolean;
  show_date_denounce: boolean;
  show_eds: boolean;
}
interface EdoAgreementModalProps {
  visible: boolean;
  onCancel: () => void;
  handbookStatusList: any;
  colleaguesList: any;
  documentID: number | undefined;
  onSend: () => void;
  settings: Settings | null;
  classConstant: string | undefined;
  classId: string | number | undefined;
}

interface Action {
  type:
    | 'setDocument'
    | 'loading'
    | 'resetState'
    | 'confirmLoading'
    | 'showSuccessMessage'
    | 'disabled';
  payload?: any;
}

interface Data {
  id: string;
  document_id: number;
  remark: null | string;
  remark2: null | string;
  document_date: Date;
  status: {
    foreign_id: number;
    full_name: string;
  };
  stage: {
    id: number;
    name: string;
  };
  employee_id: {
    id: number;
    client_id: number;
    full_name: string;
    short_name: string;
  };
  coordination: CoordinationType;
  files: any;
  remark_settings: any;
  readonly: boolean;
}

interface State {
  document: Data | null;
  isLoading: boolean;
  isAllowSend: boolean;
  isConfirmLoading: boolean;
  isSuccessSend: boolean;
  disabled: boolean;
}

const initialState: State = {
  document: null,
  isLoading: true,
  isAllowSend: false,
  isConfirmLoading: false,
  isSuccessSend: false,
  disabled: false,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setDocument':
      state.document = action.payload;
      state.isAllowSend = true;
      break;
    case 'loading':
      state.isLoading = action.payload;
      break;
    case 'disabled':
      state.disabled = action.payload;
      break;
    case 'resetState':
      state.document = null;
      state.isLoading = true;
      state.isAllowSend = false;
      state.isConfirmLoading = false;
      state.isSuccessSend = false;
      break;
    case 'confirmLoading':
      state.isConfirmLoading = action.payload;
      break;
    case 'showSuccessMessage':
      state.isSuccessSend = true;
      break;
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

function EdoAgreementModal(props: EdoAgreementModalProps) {
  const {
    visible,
    onCancel = () => {},
    documentID,
    onSend = () => {},
    colleaguesList,
    settings,
    classConstant,
    classId,
  } = props;
  const [form] = Form.useForm();
  const [documentApproversForm] = Form.useForm();
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const { getCountAgreement } = useCounter();
  const navigate = useNavigate();

  const onChangeApprovers = () => {
    try {
      dispatch({ type: 'disabled', payload: true });
    } catch (e) {
      console.log(e);
    }
  };

  const handleOk = async () => {
    try {
      dispatch({ type: 'confirmLoading', payload: true });

      let data = {};

      if(settings?.show_eds) {
        const response = await axios.post('https://print.mycent.kz/api/print_form/search', {class_id: classId});
        const convertConfig = response?.data?.data[0].print_form_converter.find(item => item.type_name === "pdf");

        data = {
          status_id: 2522, // на подписи
          document_id: documentID,
          convert_id: convertConfig.id,
        }
      } else {
        data = {
          status_id: 2522,
          document_id: documentID,
        }
      }

      await api.sendList(data);

      window.location.reload();
      dispatch({ type: 'showSuccessMessage' });

      onSend();
      getCountAgreement();
    } catch (error) {
      let message;

      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      dispatch({ type: 'confirmLoading', payload: false });
    }
  };

  const handleSaveApprovers = async (event) => {
    if (documentID) {
      dispatch({ type: 'loading', payload: true });
      const formData = documentApproversForm.getFieldsValue();
      let document_coordination: any[] = [];

      Object.entries(formData).forEach((category: any) => {
        let type: any = {
          type: category[0],
          name: category[0],
          users: [],
          queue: 0,
        };
        category[1].forEach((user: any) => {
          if (user.value) {
            let user_id: any = {
              client_id: +user.value,
            };
            type.users.push(user_id);
          }
        });
        document_coordination.push(type);
      });

      /**
       * Проверка на пустого сотрудника
       */

      const headers = {
        'Content-Type': 'application/json',
      };

      const data = {
        document_id: documentID,
        document_coordination: document_coordination,
      };

      axiosServices
        .post('document-coordination/signatoryEdit', data, { headers })
        .then((response) => {
          if (response.status === 200) {
            dispatch({ type: 'loading', payload: false });
            notification.info({
              message: 'Успешно сохранено',
            });
            dispatch({ type: 'disabled', payload: false });
            if (visible && documentID !== undefined) {
              getDocument(documentID);
            }
          } else {
            dispatch({ type: 'loading', payload: false });
            notification.error({
              message: 'Что-то не так',
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch({ type: 'loading', payload: false });
            notification.info({
              message: error.response.data.error,
            });
          }
        });
    }
  };

  const getDocument = async (id: number) => {
    try {
      dispatch({ type: 'loading', payload: true });

      setTimeout(async () => {
        const { data } = await api.getDocumentCoordination({
          document_id: id,
          document_coordination: [],
        });
        dispatch({ type: 'setDocument', payload: data.data });
      }, 500);
    } catch (error) {
      let message;
      let description;

      if (axios.isAxiosError(error)) {
        description =
          typeof error.response?.data.message !== 'undefined'
            ? error.response.data.message
            : error.response?.statusText;

        message = `Ошибка ${error.response?.status}`;
      } else {
        message = String(error);
      }
      notification.error({
        message,
        description,
      });
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  useEffect(() => {
    if (visible && documentID !== undefined) {
      getDocument(documentID);
    }
  }, [documentID, visible]);

  useEffect(() => {
    form.setFieldsValue({
      document_date: dayjs(state.document?.document_date),
      status: state.document?.status.full_name,
      stage: state.document?.stage.name,
      employee_id: state.document?.employee_id?.full_name,
      remark: state.document?.remark,
      remark2: state.document?.remark2,
    });
  }, [form, state.document]);

  useEffect(() => {
    if (!visible) {
      dispatch({ type: 'resetState' });
    }
  }, [dispatch, visible]);

  return (
    <Modal
      open={visible}
      title="Лист согласования"
      confirmLoading={state.isConfirmLoading}
      width="1000px"
      onCancel={onCancel}
      footer={[
        <Row gutter={[24, 12]}>
          <Col>
            <Button
              key="back"
              onClick={onCancel}
              disabled={state.isLoading || state.isConfirmLoading}
              hidden={state.isSuccessSend}
            >
              Закрыть
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              loading={false}
              onClick={(event: any) => handleSaveApprovers(event.target.value)}
            >
              Сохранить изменения
            </Button>
          </Col>
          {state.document?.stage?.id === 1 && (
            <Col>
              <Button
                key="submit"
                type="primary"
                loading={state.isConfirmLoading}
                onClick={handleOk}
                disabled={
                  state.disabled || state.isLoading || !state.isAllowSend
                }
                hidden={state.isSuccessSend}
              >
                Разослать на согласование
              </Button>
            </Col>
          )}
        </Row>,
      ]}
      destroyOnClose
    >
      {!state.isSuccessSend ? (
        <CustomLoader spinning={state.isLoading}>
          <Form layout="vertical" form={form} preserve={false}>
            <Row gutter={24}>
              <Col span={8} xs={24} xl={8}>
                <Form.Item label="Дата регистрации" name="document_date">
                  <DatePicker
                    format="DD.MM.YYYY"
                    style={{ width: '100%' }}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8} xs={24} xl={8}>
                <Form.Item label="Статус" name="status" hidden noStyle>
                  <Input disabled />
                </Form.Item>
                <Form.Item label="Статус" name="stage">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8} xs={24} xl={8}>
                <Form.Item label="Куратор" name="employee_id">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Divider
              orientation="left"
              orientationMargin="0"
              style={{ color: '#0057ff' }}
            >
              Дополнительные сведения
            </Divider>
            {!!state.document?.coordination && (
              <Approvers
                documentApproversForm={documentApproversForm}
                userList={state.document.coordination}
                readonly={state.document.readonly}
                isViewTable={false}
                documentID={documentID}
                colleaguesList={colleaguesList}
                onChangeApprovers={onChangeApprovers}
                classConstant={classConstant}
              />
            )}
            {state.document?.remark_settings.remark && (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="remark"
                    label={state.document?.remark_settings.remark.name}
                  >
                    <TextArea rows={6} disabled />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {state.document?.remark_settings.remark2 && (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="remark2"
                    label={state.document?.remark_settings.remark2.name}
                  >
                    <TextArea rows={6} disabled />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        </CustomLoader>
      ) : (
        <Result
          status="success"
          title="Успешно отправлено на согласование"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => navigate('/agreement')}
            >
              Перейти в ЛС
            </Button>,
            <Button key="buy" onClick={onCancel}>
              Закрыть
            </Button>,
          ]}
        />
      )}
    </Modal>
  );
}

export default EdoAgreementModal;
