import {Button, Card, Col, Empty, List, message, notification, Popconfirm, Row, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {FileDoneOutlined, PlusOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import api from "@modules/Edo/services/api";
import axios from "axios";

const {Title} = Typography;

interface DocumentData {
    id: number;
    doc_num: number;
    doc_id: number;
    doc_order_id: number;
    active: boolean;
    created_at: string;
    updated_at: string;
    stage: string;
    document_id: string;
}

interface ICreateOrderRec {
    docCreateAccess: boolean;
    currentDocId: number;
}

const CreateOrderDoc = ({ docCreateAccess, currentDocId} : ICreateOrderRec) => {
    const [currentClassId, setCurrentClassId] = useState<number | null>(null);
    const [data, setData] = useState<DocumentData[]>([]);


    const getOrderDocs = async () => {
        try {
            const docData = await api.getDocument(String(currentDocId));
            setData(docData?.data?.data?.doc_orders || []);
        } catch (e) {
            message.error('Ошибка при получении данных');
        }
    };

    const getConstantDoc = async () => {
        const response = await api.getColumnEdo({
            column: 'constant',
            value: 'OrderReport',
        });

        if(Array.isArray(response?.data)) {
            setCurrentClassId(response?.data[0]?.id);
        } else {
            setCurrentClassId(response?.data?.id);
        }
    };

    const createOrderDoc = async () => {
        try {
            const maxDocNum = Math.max(...data.map(item => item.doc_num));
            const newDocNum = (maxDocNum === -Infinity) ? 1 : maxDocNum + 1;

            await api.createOrderDoc({
                class_id: currentClassId || 8498,
                doc_num: newDocNum || 1,
                id: currentDocId,
            });

            message.success('Вы создали приказ');
            await getOrderDocs();
        } catch (error) {
            let message;

            if (axios.isAxiosError(error) && error.response) {
                message = error.response.data.message;
            } else {
                message = String(error);
            }

            notification.info({
                message: 'Ошибка',
                description: message,
            });
        }
    };

    useEffect(() => {
        getConstantDoc();
        getOrderDocs();
    }, []);

    return (
        <Card className="mt-5">
            <Title level={5} type="secondary">Приказы</Title>
            <Row style={{width: '100%'}}>
                <List
                    locale={{
                        emptyText: (
                            <Empty description="Нет созданных приказов"/>
                        ),
                    }}
                    className="w-100 mt-5"
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item key={index} className='w-100'>
                            <Row className="w-100">
                                <Col span={6} lg={6} xs={24}>
                                    <List.Item.Meta
                                        avatar={<FileDoneOutlined size={100}/>}
                                        title="Порядковый номер"
                                        description={
                                            <a
                                                href={`${item.doc_order_id}`}
                                                target="_blank"
                                            >
                                                {item?.doc_num}
                                            </a>
                                    }
                                    />
                                </Col>
                                <Col span={6} lg={6} xs={24}>
                                    {item?.document_id && <List.Item.Meta
                                        title="Номер документа"
                                        description={
                                            <a
                                                href={`${item.doc_order_id}`}
                                                target="_blank"
                                            >
                                                {item?.document_id}
                                            </a>
                                        }
                                    />}
                                </Col>
                                <Col span={6} lg={6} xs={24}>
                                    <List.Item.Meta
                                        title="Приказ создан"
                                        description={dayjs(item?.created_at).format('DD.MM.YYYY')}
                                    />
                                </Col>
                                <Col span={6} lg={6} xs={24}>
                                    <List.Item.Meta
                                        title="Статус"
                                        description={<span style={{color: '#0057ff'}}>{item?.stage}</span>}
                                    />
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Row>
            {docCreateAccess && <Row className="mt-5 w-100" justify="start">
                <Popconfirm
                    title="Подтвердить создание приказа?"
                    onConfirm={createOrderDoc}
                    okText="Подтвердить"
                    cancelText="Отмена"
                >
                    <Button
                        type="primary"
                        icon={<PlusOutlined/>}
                    >
                        Создать приказ
                    </Button>
                </Popconfirm>
            </Row>}
        </Card>
    )
};

export default CreateOrderDoc;