import { useEffect, useState } from 'react';
import { IAgreementsProps, ISignatoryData, IDocumentSignatoryTypeUser, IDocClassCoordination } from "./IAgreementsCS";

import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import api from '@modules/Agreement/services/api';

import {
  Card,
  Form,
  Row,
  Col,
  message,
  Typography,
  Select,
  Button,
} from 'antd';
import GenerateLS from '@pages/Edo/GenerateLS';

const { Title } = Typography;

function AgreementsCS({ class_id, docEdoId, attributes, disabledButton }: IAgreementsProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [agreementsCS, setAgreementsCS] = useState<ISignatoryData>();
  const [selectedInitiator, setSelectedInitiator] = useState<number>();
  const [selectedVerifier, setSelectedVerifier] = useState<number>();
  const [agreementStatus, setAgreementStatus] = useState<boolean>(false);

  const [] = useState();

  const fetchAgreements = async () => {
    try {
      setLoading(true);
      const response = await api.getDataAgreementCS(class_id);
      if (response.status === 200) {
        const data = response.data.data;
        setAgreementsCS(data);

        const verifierUsers = data.signatory_verifier?.document_signatory_type_users[0];
        const initiatorUsers = data.signatory_initiator?.document_signatory_type_users;

        const updatedInitiatorUser = initiatorUsers.find((item: IDocumentSignatoryTypeUser) => {
            return item.info.id !== verifierUsers.info.id;
        });

        setSelectedInitiator(verifierUsers?.info.id);
        setSelectedVerifier(updatedInitiatorUser?.info.id);

        form.setFieldsValue({
          signatory_initiator_user: verifierUsers?.info.full_name,
          signatory_verifier_user: updatedInitiatorUser?.info.full_name,
        });
      }
    } catch (e) {
      message.error('Ошибка');
    } finally {
      setLoading(false);
    }
  };

  const formatDocs = async () => {
    const userDestination = attributes.find((item) => {
        return item.attribute_type.short_name === 'АДРЕСАТ';
    });

    console.log('attributes', attributes);

    const formatedData = {
        id: docEdoId,
        coordination: [
            {
                "user_id": selectedInitiator,
                "type": "signatory_initiator"
            },
            {
                "user_id": selectedVerifier,
                "type": "signatory_verifier"
            },
            {
                "user_id": userDestination?.number_value?.id,
                "type": "destination", // адресат
            }
        ]
    }
    try {
        const data = await api.setSignatureTypeCoordByDocId(formatedData);
        if (data.status === 200) {
            setAgreementStatus(true);
            message.success(data.data.data);
        }
    } catch (e) {
        message.error('Ошибка');
    }
  }

  const handleInitiatorChange = (value: string) => {
    setSelectedInitiator(+value);
    form.setFieldsValue({ signatory_initiator_user: value });
  };

  const handleVerifierChange = (value: string) => {
    setSelectedVerifier(+value);
    form.setFieldsValue({ signatory_verifier_user: value });
  };

  useEffect(() => {
    fetchAgreements();
  }, [class_id]);

  return (
    <CustomLoader spinning={loading}>
      <Card className="mt-5">
        <Title level={5} type="secondary">
          Списки согласующих СС
        </Title>
        <Form
          form={form}
          name="agreement"
        >
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Title level={5}>{agreementsCS?.signatory_initiator.type_name}</Title>
              <Form.Item name="signatory_initiator_user">
                <Select
                  placeholder="Выберите инициатора"
                  onChange={handleInitiatorChange}
                >
                  {agreementsCS?.signatory_initiator.document_signatory_type_users.map((user: IDocumentSignatoryTypeUser) => (
                    <Select.Option
                      key={user.info.id}
                      value={user.info.id}
                      disabled={user.info.id === (selectedVerifier ? selectedVerifier : 0)}
                    >
                      {user.info.full_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Title level={5}>{agreementsCS?.signatory_verifier.type_name}</Title>
              <Form.Item name="signatory_verifier_user">
                <Select
                  placeholder="Выберите проверяющего"
                  onChange={handleVerifierChange}
                >
                  {agreementsCS?.signatory_verifier.document_signatory_type_users.map((user: IDocumentSignatoryTypeUser) => (
                    <Select.Option
                      key={user.info.id}
                      value={user.info.id}
                      disabled={user.info.id === (selectedInitiator ? selectedInitiator : 0)}
                    >
                      {user.info.full_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row gutter={[20,0]}>
            <Col>
                <Button type="primary" onClick={() => { formatDocs() }}>
                    Сохранить
                </Button>
            </Col>
            {
                agreementStatus && (
                    <Col>
                        <Form name="listForm">
                            <GenerateLS disabled={disabledButton} />
                        </Form>
                    </Col>
                )
            }
        </Row>
      </Card>
    </CustomLoader>
  );
}

export default AgreementsCS;
