/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { PlusOutlined, CloseOutlined, FileDoneOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Typography,
  Divider,
  notification,
  Input,
  Popconfirm,
  Pagination, List, Empty,
} from 'antd';
import axios from 'axios';

import { IRowColumnEditor } from '../types';
import { ColleaguesListType } from '../../../hooks/useColleagues';
import { HandbookListType } from '../../../hooks/useHandbook';
import EdoRowColumnField from './EdoRowColumnField';
import dayjs from 'dayjs';
import DateMask from '@modules/Edo/DateMask';
import api from '../services/api';
import apiEdo from '../../../services/api';
import ModalInvestDecisions from '@modules/Edo/ModalInvestDecisions';
import settings from '@modules/Settings';

const { Title } = Typography;

interface Props {
  colleaguesList: ColleaguesListType;
  handbookList: HandbookListType;
  departmentList: ColleaguesListType;
  id: number;
  row_column_editor: IRowColumnEditor[];
  data: any;
  isDisabledForm: boolean;
  button_editor: any[];
  attrForm: any;
  docData?: any;
  isAdmin: any;
  isDisabledStatus: boolean;
  constant: string;
  isDocEditCs: boolean;
}

interface IDataList {
  "editor_id": number,
  "document_class_id": number,
  "required": boolean,
  "readonly": boolean,
  "field_name": string,
  "data_type_id": number,
  "type": string,
  "dicti_id": null | number,
  "sort_kind": null | number,
  "children_filter": boolean,
  "dept_filter": boolean,
  "summaries": number,
  "display_no": null | number,
  "order_no": null | number,
  "order_no_db": null | number,
  "document_id": null | number,
  "num_value": {
    "id": number,
    "parent_id": number,
    "short_name": string,
    "full_name": string,
    "constant": string,
    "constant1": null | string,
    "company_id": number,
    "has_child": number,
    "foreign_id": number,
    "active": boolean,
    "text_code": null | string,
    "num_code": null | string,
    "updated_by": number,
    "created_by": number,
    "created_at": string,
    "updated_at": string,
    "enabled_company_ids": string
  },
  "date_value": null | string,
  "char_value": string,
  "id": number,
  "constant": string,
  "document_row_id": number,
  "json": null | string,
  "document_link_id": number,
  "group_column": number
}

const sortByDisplayNo = (itemA, itemB) =>
  parseInt(itemA.display_no, 10) - parseInt(itemB.display_no, 10);

const arraySecuritiesDoc: string[] = [
  "AffReport",
  "RecReport",
  "ConcReport",
  "ProtReport"
];

const checkSecuritiesDoc = (array, value) => {
  return array.includes(value);
}

function EdoTabularForm({
  id,
  handbookList = [],
  colleaguesList = [],
  departmentList = [],
  row_column_editor = [],
  button_editor = [],
  data = [],
  isDisabledForm = false,
  attrForm,
  docData,
  isAdmin,
  isDisabledStatus,
  constant,
  isDocEditCs
}: Props) {
  const [isOpenInvestModal, setIsOpenInvestModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPagintaion, setIsPagination] = useState(false);
    const [dataList, setDataList] = useState<IDataList[]>([]);
  const [form] = Form.useForm();
  const handleDeleteTabular = async (name, remove) => {
    try {
      setIsLoading(true);

      // TODO remove
      const { row_column_editor } = form.getFieldsValue();

      const idList = Object.values(row_column_editor[name]).map(
        (item: any) => item.document_row_id
      );

      if (idList[0]) {
        await api.removeTabular(idList[0]);
      }

      remove(name);

      notification.info({
        message: 'Уведомление',
        description: 'Данные успешно удалены',
      });
    } catch (error) {
      let message;

      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Проверяем что текущий документ - Заявление на трудовой отпуск или Заявление на экологический отпуск
  const isVacation = () => {
    switch (docData?.document_id?.substring(0, 3).toUpperCase()) {
      case 'ЗТО':
      case 'ЗЭО':
        return true;
      default:
        return false;
    }
  };
  const copyAndAddData = (key) => {
    const allData = form.getFieldsValue().row_column_editor;

    const data = form.getFieldsValue().row_column_editor[key];

    let copyData = JSON.parse(JSON.stringify(allData));

    copyData.splice(copyData.length - 1, 1, data);

    copyData.forEach((row, index) => {
      let keys = Object.keys(row);
      return keys.forEach((key) => {
        switch (row[key].type) {
          case 'SUBJECT':
          case 'SUBJECT_SHORT':
            row[key].department = row[key].department;
            row[key].duty = row[key].duty;
            row[key].num_value = row[key].num_value;
            row[key].char_value = row[key].char_value;
            break;
          case 'EMPL':
          case 'DEPTEMPL':
            row[key].department = row[key].department;
            row[key].duty = row[key].duty;
            row[key].num_value = row[key].num_value;
            row[key].full_name = row[key].field_name;
            break;
          case 'DEPT':
            row[key].department_id = row[key].num_value;
            row[key].department = row[key].department;
            row[key].num_value = row[key].num_value;
            row[key].full_name = row[key].field_name;
            break;
          case 'DOCS':
          case 'DICTI':
            row[key].num_value = row[key].num_value;
            row[key].full_name = row[key].field_name;
            break;
          case 'DUTY':
            row[key].num_value = row[key].num_value;
            row[key].full_name = row[key].field_name;
            break;
          case 'SQL':
            row[key].num_value = row[key].num_value;
            break;
          case 'DATE':
            row[key].date_value =
              (dayjs(row[key].date_value).isValid() &&
                index !== allData.length - 1) ||
              row[key]?.full_name?.toLowerCase() ===
                'проработанный период, с' ||
              row[key]?.full_name?.toLowerCase() === 'проработанный период, по'
                ? dayjs(row[key].date_value)
                : null;
            break;
          default:
            break;
        }
      });
    });

    const row_column_editor = copyData.map((row, index) => {
      let keys = Object.keys(row);
      if (index == copyData.length - 1) {
        return Object.fromEntries(
          keys.map((key) => [
            `${key}`,
            {
              display_no: row[key].display_no,
              editor_id: parseInt(key.split('-')[1]),
              order_no: copyData.length,
              type: row[key].type,
              document_id: id,
              date_value: row[key].date_value,
              num_value: row[key].num_value,
              char_value: row[key].char_value,
              // id: row[key].id,
              full_name: row[key].field_name,
              department: row[key].department,
              duty: row[key].duty,
              sql: row[key].sql,
              // document_row_id: row[key].document_row_id,
            },
          ])
        );
      } else {
        return Object.fromEntries(
          keys.map((key) => [
            `${key}`,
            {
              display_no: row[key].display_no,
              editor_id: parseInt(key.split('-')[1]),
              order_no: row[key].order_no,
              type: row[key].type,
              document_id: id,
              date_value: row[key].date_value,
              num_value: row[key].num_value,
              char_value: row[key].char_value,
              id: row[key].id,
              full_name: row[key].field_name,
              department: row[key].department,
              duty: row[key].duty,
              sql: row[key].sql,
              document_row_id: row[key].document_row_id,
            },
          ])
        );
      }
    });

    form.setFieldsValue({ row_column_editor });
  };

  const getColumnEditor = () => {
    if (row_column_editor?.length) {
      let copyData = JSON.parse(JSON.stringify(row_column_editor));
      copyData.forEach((row) => {
        switch (row.type) {
          case 'TEXT':
            row.char_value = row.def_char_value;
            break;
          case 'DICTI':
            row.num_value = row.def_num_value?.id;
            break;
          case 'DATE':
            row.date_value = dayjs(row.def_date_value).isValid()
              ? dayjs(row.def_date_value)
              : null;
            break;
          default:
            break;
        }
      });
      const send = Object.fromEntries(
        copyData.map((row) => [
          `id-${row.id}`,
          {
            display_no: row.display_no,
            editor_id: row.id,
            order_no: row.order_no_db,
            type: row.type,
            document_id: id,
            destination_id: row.destination_id,
            executor_id: row.executor_id,
            date_value: row.date_value,
            num_value: row.num_value,
            char_value: row.char_value,
            full_name: row.field_name,
            department: row.department,
            duty: row.duty,
            document_row_id: row.document_row_id,
            // full_name: column.field_name,
          },
        ])
      );


      return [send];
    }
  }

  useEffect(() => {
    if (row_column_editor?.length) {
      form.setFieldsValue({ row_column_editor: getColumnEditor() });
    }
  }, [row_column_editor]);

  const copyWorkerData = () => {
    const key = 0;
    const allData = form.getFieldsValue().row_column_editor;
    const data = form.getFieldsValue().row_column_editor[key];
    if (!data) {
      return;
    }

    let copyData = JSON.parse(JSON.stringify(allData));
    copyData.splice(copyData.length - 1, 1, data);

    const row_column_editor = copyData.map((row, index) => {
      let keys = Object.keys(row);
      if (index === copyData.length - 1) {
        return Object.fromEntries(
          keys.map((key) => [
            `${key}`,
            {
              display_no: row[key].display_no,
              editor_id: parseInt(key.split('-')[1]),
              order_no: copyData.length,
              type: row[key].type,
              document_id: id,
              char_value: row[key].char_value,
              full_name: row[key].field_name,
              sql: row[key].sql,
              num_value: row[key].num_value,
              department: row[key].department,
              duty: row[key].duty,
              date_value: null,
            },
          ])
        );
      } else {
        return Object.fromEntries(
          keys.map((key) => [
            `${key}`,
            {
              display_no: row[key].display_no,
              editor_id: parseInt(key.split('-')[1]),
              order_no: copyData.length,
              type: row[key].type,
              document_id: id,
              char_value: row[key].char_value,
              full_name: row[key].field_name,
              sql: row[key].sql,
              num_value: row[key].num_value,
              department: row[key].department,
              duty: row[key].duty,
              date_value: dayjs(row[key].date_value).isValid()
                ? dayjs(row[key].date_value)
                : null,
            },
          ])
        );
      }
    });

    form.setFieldsValue({ row_column_editor });
  };

  const onChangeByTd = async (id, name) => {
    // const { data: doc } = await api.getDocument(`${docData.id}`);
    const val = data?.data[name].filter(
      (item) => parseInt(item.editor_id) === parseInt(id)
    )[0];

    switch (val.type) {
      case 'SUBJECT':
      case 'SUBJECT_SHORT':
        if (
          parseInt(val.num_value) !==
          parseInt(
            form.getFieldsValue().row_column_editor[name][`id-${id}`].num_value
          )
        ) {
          const { data } = await api.testUpdateOneRowTable({
            ...form.getFieldsValue().row_column_editor[name][`id-${id}`],
          });
        }
        break;
      case 'SUBJECT_CONTRACTOR':
        if (
          parseInt(val.num_value) !==
          parseInt(
            form.getFieldsValue().row_column_editor[name][`id-${id}`].num_value
          )
        ) {
          setTimeout(async () => {
            const { data } = await api.testUpdateOneRowTable({
              ...form.getFieldsValue().row_column_editor[name][`id-${id}`],
            });
          }, 500);
        }
        break;
      case 'SQL':
        if (
          parseInt(val.num_value) !==
          parseInt(
            form.getFieldsValue().row_column_editor[name][`id-${id}`].num_value
          )
        ) {
          const { data } = await api.testUpdateOneRowTable({
            ...form.getFieldsValue().row_column_editor[name][`id-${id}`],
          });
        }
        break;
      case 'DEPTEMPL':
        setTimeout(async () => {
          if (
            val.num_value.id !==
            form.getFieldsValue().row_column_editor[name][`id-${id}`].num_value
          ) {
            Object.keys(form.getFieldsValue().row_column_editor[name]).map(
              async (key) => {
                if (
                  form.getFieldsValue().row_column_editor[name][key].type ===
                    'DEPT' &&
                  form
                    .getFieldsValue()
                    .row_column_editor[name][key].full_name.toLowerCase() ===
                    'подразделение'
                ) {
                  const { data } = await api.testUpdateOneRowTable({
                    ...form.getFieldsValue().row_column_editor[name][key],
                  });
                } else if (
                  form.getFieldsValue().row_column_editor[name][key].type ===
                  'DUTY'
                ) {
                  const { data } = await api.testUpdateOneRowTable({
                    ...form.getFieldsValue().row_column_editor[name][key],
                  });
                } else if (
                  form
                    .getFieldsValue()
                    .row_column_editor[name][key].full_name.toLowerCase() ===
                  'доступное кол-во дней'
                ) {
                  const { data } = await api.testUpdateOneRowTable({
                    ...form.getFieldsValue().row_column_editor[name][key],
                    char_value:
                      form.getFieldsValue().row_column_editor[name][`id-${id}`]
                        .adminDay,
                  });
                }
              }
            );
            const { data } = await api.testUpdateOneRowTable({
              ...form.getFieldsValue().row_column_editor[name][`id-${id}`],
            });
          }
        }, 1000);
        break;
      case 'DICTI':
        if (
          val.num_value.id !==
          form.getFieldsValue().row_column_editor[name][`id-${id}`].num_value
        ) {
          const { data } = await api.testUpdateOneRowTable({
            ...form.getFieldsValue().row_column_editor[name][`id-${id}`],
          });
        }
        break;
      case 'DUTY':
        if (
          val.num_value.id !==
          form.getFieldsValue().row_column_editor[name][`id-${id}`].num_value
        ) {
          const { data } = await api.testUpdateOneRowTable({
            ...form.getFieldsValue().row_column_editor[name][`id-${id}`],
          });
        }
        break;
      case 'DEPT':
        if (
          val.num_value.id !==
          form.getFieldsValue().row_column_editor[name][`id-${id}`].num_value
        ) {
          const { data } = await api.testUpdateOneRowTable({
            ...form.getFieldsValue().row_column_editor[0][`id-${id}`],
          });
        }
        break;
      case 'FLOAT':
        // setTimeout(async () => {
        if (
          parseInt(val.char_value) !==
          parseInt(
            form.getFieldsValue().row_column_editor[name][`id-${id}`].char_value
          )
        ) {
          const { data } = await api.testUpdateOneRowTable({
            ...form.getFieldsValue().row_column_editor[0][`id-${id}`],
          });
        }
        // }, 1000);
        break;
      case 'TEXT':
        if (
          val.char_value !==
          form.getFieldsValue().row_column_editor[name][`id-${id}`].char_value
        ) {
          const { data } = await api.testUpdateOneRowTable({
            ...form.getFieldsValue().row_column_editor[0][`id-${id}`],
          });
        }
        break;
      case 'INTEGER':
        if (
          parseInt(val.num_value) !==
          parseInt(
            form.getFieldsValue().row_column_editor[name][`id-${id}`].num_value
          )
        ) {
          const { data } = await api.testUpdateOneRowTable({
            ...form.getFieldsValue().row_column_editor[0][`id-${id}`],
          });
        }
        break;
      case 'DATE':
        if (
          val.date_value !==
          dayjs(
            form.getFieldsValue().row_column_editor[name][`id-${id}`].date_value
          ).format('YYYY-MM-DD')
        ) {
          const { data } = await api.testUpdateOneRowTable({
            ...form.getFieldsValue().row_column_editor[name][`id-${id}`],
            date_value: dayjs(
              form.getFieldsValue().row_column_editor[name][`id-${id}`]
                .date_value
            ).format('YYYY-MM-DD'),
          });
        }
        break;
    }
  };

  const filterObjects = (data) => {
    const filteredData = {};

    for (const key in data[0]) {
      if (data[0][key].char_value !== null) {
        filteredData[key] = data[0][key];
      }
    }

    return filteredData;
  }

  const parseValue = (value) =>  {
    try {
      // Пытаемся распарсить значение как JSON
      let parsedValue = JSON.parse(value);

      // Проверяем, является ли распарсенное значение массивом
      if (Array.isArray(parsedValue)) {
        return parsedValue;
      }
    } catch (e) {}

    // Если значение не является массивом или парсинг не удался, возвращаем его как есть
    return [value];
  }

  const changeFields = (data) => {
    let copyData = JSON.parse(JSON.stringify(data));


    copyData.forEach((row) => {
      return row.forEach((column) => {
        switch (column.type) {
          case 'SUBJECT':
          case 'SUBJECT_SHORT':
            column.num_value = column.num_value;
            column.char_value = column.char_value;
            column.full_name = column.field_name;
            break;
          case 'EMPL':
          case 'DEPTEMPL':
            column.department = column.num_value?.department;
            column.duty = column.num_value?.duty?.duty_name;
            column.num_value = column.num_value?.id;
            column.full_name = column.field_name;
            column.destination_id = column.destination_id;
            column.executor_id = column.executor_id;
            break;
          case 'DEPT':
            column.department_id = column.num_value?.id;
            column.department = column.num_value?.full_name;
            column.num_value = column.num_value?.id;
            column.full_name = column.field_name;
            break;
          case 'DOCS':
            column.char_value = column.char_value;
            column.num_value = column.num_value;
            column.full_name = column.field_name;
            break;
          case 'DICTI':
            column.num_value = column.num_value?.id;
            column.full_name = column.field_name;
            if(column.editor_constant === "COLUMN_14") column.char_value = column.char_value ? parseValue(column.char_value) : [];
            break;
          case 'DUTY':
            column.duty = column.num_value?.duty_name;
            column.duty_id = column.num_value?.id;
            column.num_value = column.num_value?.id;
            column.full_name = column.field_name;
            break;
          case 'INTEGER':
          case 'NUMBER':
            column.num_value =
              typeof column.num_value == 'object' ? '' : column.num_value;
            break;
          case 'FLOAT':
            column.char_value =
              typeof column.char_value == 'object' ? '' : column.char_value;
            break;
          case 'SQL':
            column.duty = column?.char_value;
            column.num_value = column.num_value;
            break;
          case 'AGREEMENT':
            column.char_value = column.char_value;
            column.full_name = column.field_name;
            break;
          case 'CHECKBOX':
            column.num_value = column.num_value == 0 ? false : true;
            column.full_name = column.field_name;
            break;
          case 'DATE':
            column.date_value = dayjs(column.date_value).isValid()
              ? dayjs(column.date_value)
              : null;
            break;
          case 'MULTI_SELECT':
            column.json = column.json !== null ? JSON.parse(column.json).map(Number) : [];
            break;
          default:
            break;
        }
      });
    });

    const row_column_editor = copyData.map((row) =>
      Object.fromEntries(
        row.map((column) => [
          `id-${column.editor_id}`,
          {
            display_no: column.display_no,
            editor_id: column.editor_id,
            order_no: column.order_no,
            type: column.type,
            document_id: id,
            destination_id: column.destination_id,
            executor_id: column.executor_id,
            date_value: column.date_value,
            num_value: column.num_value,
            char_value: column.char_value,
            id: column.id,
            full_name: column.field_name,
            department: column.department,
            duty: column.duty,
            document_row_id: column.document_row_id,
            json: column.json,
          },
        ])
      )
    );

    if(checkSecuritiesDoc(arraySecuritiesDoc, docData?.class?.constant)) {
      const filteredData = filterObjects(getColumnEditor());
      const mergedObject = Object.assign(filteredData, ...row_column_editor);

      form.setFieldsValue({ row_column_editor: [mergedObject] });
    } else {
      form.setFieldsValue({ row_column_editor });
    }
  };

  useEffect(() => {
    if (data?.data?.length) {
      changeFields(data.data);
    }
  }, [data, form]);

  const resetTabular = (remove) => {
    const data = form.getFieldsValue().row_column_editor;
    const removeItem = data.filter((val, index) => index !== 0);
    form.resetFields();
    remove(removeItem.map((val, index) => index));
  };

  const onChangePagination = async (page: number) => {
    try {
      setIsPagination(true);
      const { data } = await api.getColumnByDocId({
        document_id: id,
        page,
      });

      let copyData = JSON.parse(JSON.stringify(data.data.data));
      const paginationData = copyData.map((field) => {
        const key = Object.keys(field);
        return field[`${key}`];
      });

      changeFields(paginationData);
    } finally {
      setIsPagination(false);
    }
  };

  const deleteInvestDocument = async (document) => {
    try {
      await apiEdo.edo.deleteRowColumn(document.id);

      window.location.reload();
    } catch (error) {
      let message;
      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    }
  };

  useEffect(() => {
    if(docData?.class?.constant === 'ProtReport') {
      const filteredItems = docData.doc_row.data.flat().filter(item => item.group_column === 1);
      setDataList(filteredItems);
    }
  }, [docData]);


  return (
    <Card data-testid="edo-row-column-form" className="mt-5">
      <Title level={5} type="secondary">
        Табличная часть
      </Title>
      <Form
        name="tabular"
        layout="vertical"
        autoComplete="off"
        form={form}
        validateMessages={{ required: 'Обязательное поле' }}
        initialValues={{ row_column_editor: [undefined] }}
        disabled={isDisabledForm}
      >
        <Form.List
          name="row_column_editor"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error('Заполните табличную часть'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map(({ key, name }, index) => (
                  <React.Fragment key={key}>
                    <Row gutter={14} align="bottom">
                      {[...row_column_editor]
                        .sort(sortByDisplayNo)
                        .map((item) => {
                          return (
                            <React.Fragment key={item.id}>
                              <EdoRowColumnField
                                isAdmin={isAdmin}
                                settings={item}
                                name={name}
                                handbookList={
                                  item.dicti?.length ? item.dicti : handbookList
                                }
                                departmentList={departmentList}
                                colleaguesList={colleaguesList}
                                isDisabledForm={isDisabledForm}
                                isDouble={index > 0}
                                isVacation={isVacation()}
                                attrForm={attrForm}
                                id={id}
                                docData={docData}
                                onChangeByTd={onChangeByTd}
                                isDisabledStatus={isDisabledStatus}
                                isDocEditCs={isDocEditCs}
                              />
                              {/**
                               * Формируем объект для поля со свойсвами:
                               * @var type
                               * @var document_id
                               * @var display_no
                               * @var editor_id
                               * @var order_no
                               * @var full_name
                               * @var document_row_id
                               * @var destination_id
                               * @var executor_id
                               * @var id для обновления
                               */}
                              <Form.Item
                                hidden
                                name={[name, `id-${item.id}`, 'type']}
                                initialValue={item.type}
                              >
                                <Input disabled />
                              </Form.Item>
                              {item.executor_id ? (
                                <Form.Item
                                  hidden
                                  name={[name, `id-${item.id}`, 'executor_id']}
                                  initialValue={item.executor_id}
                                >
                                  <Input disabled />
                                </Form.Item>
                              ) : (
                                ''
                              )}
                              {item.destination_id ? (
                                <Form.Item
                                  hidden
                                  name={[
                                    name,
                                    `id-${item.id}`,
                                    'destination_id',
                                  ]}
                                  initialValue={item.destination_id}
                                >
                                  <Input disabled />
                                </Form.Item>
                              ) : (
                                ''
                              )}
                              <Form.Item
                                hidden
                                name={[name, `id-${item.id}`, 'full_name']}
                                initialValue={
                                  item.full_name
                                    ? item.full_name
                                    : item.field_name
                                }
                              >
                                <Input disabled />
                              </Form.Item>
                              <Form.Item
                                hidden
                                name={[name, `id-${item.id}`, 'document_id']}
                                initialValue={id}
                              >
                                <Input disabled />
                              </Form.Item>
                              <Form.Item
                                hidden
                                name={[name, `id-${item.id}`, 'display_no']}
                                initialValue={item.display_no}
                              >
                                <Input disabled />
                              </Form.Item>
                              <Form.Item
                                hidden
                                name={[name, `id-${item.id}`, 'order_no']}
                                initialValue={name + 1}
                              >
                                <Input disabled />
                              </Form.Item>
                              <Form.Item
                                hidden
                                name={[
                                  name,
                                  `id-${item.id}`,
                                  'document_row_id',
                                ]}
                                initialValue={item.document_row_id}
                              >
                                <Input disabled />
                              </Form.Item>
                              <Form.Item
                                hidden
                                name={[name, `id-${item.id}`, 'editor_id']}
                                initialValue={item.id}
                              >
                                <Input disabled />
                              </Form.Item>
                              <Form.Item
                                hidden
                                name={[name, `id-${item.id}`, 'id']}
                              >
                                <Input disabled />
                              </Form.Item>
                            </React.Fragment>
                          );
                        })}
                      {constant === 'ProtReport' &&
                        <Row className="w-100">
                        <List
                          locale={{
                            emptyText: (
                              <Empty description="Нет привязанных инвест. решений к документу"/>
                            ),
                          }}
                          style={{width: '100%'}}
                          itemLayout="horizontal"
                          dataSource={dataList}
                          renderItem={(item, index) => (
                            <List.Item
                              actions={[
                                docData?.stage?.slug !== null && docData?.stage?.slug.toLowerCase() === "in_work" && (<Button
                                type="primary"
                                danger
                                key={index}
                                onClick={() => deleteInvestDocument(item)}
                              >
                                Удалить
                              </Button>)]}
                            >
                              <List.Item.Meta
                                avatar={<FileDoneOutlined />}
                                title={
                                  <a
                                    href={`${item.document_link_id}`}
                                    target="_blank"
                                  >
                                    {item.char_value}
                                  </a>
                                }
                                description={item.field_name}
                              />
                            </List.Item>
                          )}
                        />
                      </Row>}
                      <Col span={24}>
                        <Row justify="space-between">
                          {button_editor.filter(
                            (item) =>
                              item.alias == 'BUTTONCOPY' && item.active == '1'
                          ).length > 0 && (
                            <Col>
                              <Button
                                type="primary"
                                ghost
                                onClick={() => {
                                  add();
                                  copyAndAddData(name);
                                  DateMask();
                                }}
                                icon={<PlusOutlined />}
                              >
                                Дублировать
                              </Button>
                            </Col>
                          )}
                          {button_editor.filter(
                            (item) =>
                              item.alias == 'BUTTONCLEAR' && item.active == '1'
                          ).length > 0 && (
                            <Col>
                              <Button
                                danger
                                onClick={() =>
                                  handleDeleteTabular(name, remove)
                                }
                              >
                                Удалить
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                  </React.Fragment>
                ))}
                <Row gutter={24}>
                  {button_editor.filter(
                    (item) =>
                      (item.alias == 'BUTTONADD' || item.alias == 'BUTTON1') &&
                      item.active == '1'
                  ).length > 0 && (
                    <Col>
                      <Form.Item>
                        <Button
                          type="primary"
                          ghost
                          onClick={() => {
                            add();
                            if (isVacation()) {
                              copyWorkerData();
                            }
                          }}
                          icon={<PlusOutlined />}
                        >
                          Добавить
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                  {constant === 'ProtReport' && <Col>
                    <Form.Item>
                      <Button
                        type="primary"
                        ghost
                        icon={<PlusOutlined />}
                        onClick={() => setIsOpenInvestModal(true)}
                      >
                        Добавить инвест. решение
                      </Button>
                    </Form.Item>
                  </Col>}
                  <ModalInvestDecisions
                    isModalOpen={isOpenInvestModal}
                    handleCancel={() => {
                      setIsOpenInvestModal(false);
                      window.location.reload();
                    }}
                    handbookList={handbookList}
                    departmentList={departmentList}
                    colleaguesList={colleaguesList}
                  />
                  <Col>
                    <Form.Item>
                      <Popconfirm
                        title="Подтвердить очищение данных"
                        onConfirm={() => resetTabular(remove)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button
                          danger
                          htmlType="button"
                          icon={<CloseOutlined />}
                          disabled={constant === 'OrderReport' ? !isDocEditCs : false}
                          // loading={isLoading}
                        >
                          Очистить данные
                        </Button>
                      </Popconfirm>
                    </Form.Item>
                  </Col>
                </Row>
                {data.total > 10 && (
                  <Row gutter={24}>
                    <Col
                      span={24}
                      className="d-flex align-center justify-center"
                    >
                      <Pagination
                        defaultCurrent={data && data.current_page}
                        total={data && data.total}
                        onChange={(page) => onChangePagination(page)}
                        disabled={isPagintaion}
                        showSizeChanger={false}
                      />
                    </Col>
                  </Row>
                )}
                <Form.ErrorList errors={errors} className="mb-3" />
              </>
            );
          }}
        </Form.List>
      </Form>
    </Card>
  );
}

export default EdoTabularForm;
