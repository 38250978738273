import { Button } from 'antd';

function GenerateLS(props) {
  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={props.disabled ? props.disabled : false}
    >
      Сформировать лист согласования 
    </Button>
  );
}

export default GenerateLS;
