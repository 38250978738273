import { Card, Form, Input, Row, Typography } from 'antd';
import React, { useEffect } from 'react';

import api from '@modules/Agreement/services/api';
import { HandbookListType } from '../../../hooks/useHandbook';
import { ColleaguesListType } from '../../../hooks/useColleagues';
import EdoAttributeField from './EdoAttributeField';
import { IAtrributeEditor } from '../types';
import dayjs from 'dayjs';

const { Title } = Typography;

interface Props {
  colleaguesList: ColleaguesListType;
  handbookList: HandbookListType;
  id: number;
  attribute_editor: IAtrributeEditor[];
  data: any;
  departmentList: any[];
  isDisabledForm: boolean;
  form: any;
  isDisabledStatus: boolean;
  constant: string;
  isDocEditCs: boolean;
}

function EdoAttributeForm({
  id,
  attribute_editor = [],
  handbookList = [],
  colleaguesList = [],
  departmentList = [],
  data = [],
  isDisabledForm = false,
  form,
  isDisabledStatus,
  constant,
  isDocEditCs
}: Props) {
  // const [form] = Form.useForm();

  const sortByOrderNo = (itemA: IAtrributeEditor, itemB: IAtrributeEditor) =>
    itemA.order_no - itemB.order_no;

  useEffect(() => {
    if (data?.length) {
      /**
       * TODO:
       */
      const copyData = JSON.parse(JSON.stringify(data));
      copyData.forEach((item) => {
        switch (item.attribute_type.text_code) {
          case 'SUBJECT':
          case 'SUBJECT_SHORT':
            item.department = item.number_value?.department;
            item.duty = item.number_value?.duty?.duty_name;
            item.full_name = item.number_value?.full_name;
            item.number_value = item.number_value?.id;
            item.destination_id = item.number_value?.id;
            break;
          case 'EMPL':
          case 'DEPTEMPL':
            item.department = item.number_value?.department;
            item.duty = item.number_value?.duty?.duty_name;
            item.number_value = item.number_value?.id;
            item.destination_id = item.number_value?.id;
            break;
          case 'DEPT':
          case 'DOCS':
          case 'DICTI':
          case 'DUTY':
            item.number_value = item.number_value?.id;
            break;
          case 'DATE':
            item.date_value = dayjs(item.date_value).isValid()
              ? dayjs(item.date_value)
              : null;
            break;
          default:
            break;
        }
      });

      const dataToSetFields = Object.fromEntries(
        copyData.map((item) => [`id-${item.editor_id}`, item])
      );

      form.setFieldsValue(dataToSetFields);
    }
  }, [data, form]);

  return (
    <Card data-testid="edo-attribute-form" className="mt-5">
      <Title level={5} type="secondary">
        Дополнительные атрибуты
      </Title>
      <Form
        name="attributes"
        layout="vertical"
        autoComplete="off"
        form={form}
        validateMessages={{ required: 'Обязательное поле' }}
        disabled={isDisabledForm}
      >
        <Row gutter={24} align="bottom">
          {[...attribute_editor].sort(sortByOrderNo).map((item) => {
            return (
              <React.Fragment key={item.id}>
                <EdoAttributeField
                  departmentList={departmentList}
                  colleaguesList={colleaguesList}
                  handbookList={handbookList}
                  settings={item}
                  isDisabledForm={constant === 'OrderReport' ? !isDocEditCs : isDisabledForm}
                  id={id}
                  form={form}
                />

                {/**
                 * Формируем объект для поля со свойствами:
                 * @var attribute_type_id
                 * @var class_id
                 * @var document_id
                 * @var editor_id
                 * @var order_no
                 * @var id для обновления
                 */}
                <Form.Item
                  hidden
                  name={[`id-${item.id}`, 'attribute_type_id']}
                  initialValue={item.attribute_type.id}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  hidden
                  name={[`id-${item.id}`, 'destination_id']}
                  initialValue={item.destination_id}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  hidden
                  name={[`id-${item.id}`, 'attribute_name']}
                  initialValue={item.attribute_type.constant1}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  hidden
                  name={[`id-${item.id}`, 'class_id']}
                  initialValue={item.class_id}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  hidden
                  name={[`id-${item.id}`, 'document_id']}
                  initialValue={id}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  hidden
                  name={[`id-${item.id}`, 'order_no']}
                  initialValue={item.order_no}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  hidden
                  name={[`id-${item.id}`, 'editor_id']}
                  initialValue={item.id}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item hidden name={[`id-${item.id}`, 'id']}>
                  <Input disabled />
                </Form.Item>
              </React.Fragment>
            );
          })}
        </Row>
      </Form>
    </Card>
  );
}

export default EdoAttributeForm;
